import React, { ReactElement, useContext } from "react";
import { ThemeContext, ThemeProvider } from "styled-components";

import useIsInEditMode from "hooks/useIsInEditMode";
import { addEditAttributes } from "utils/episerver";

import {
    Container,
    Heading,
    StyledTextBlock,
    Documents,
    Document,
} from "./FactBox.styled";
import FactBoxProps from "./FactBoxProps";

const FactBox = ({
    identifier,
    anchorName,
    className,
    theme,
    heading,
    mainBody,
    documentLinks,
    children,
    icon,
    lightbox = false,
}: FactBoxProps): ReactElement => {
    const isInEditMode = useIsInEditMode();

    heading = heading || (isInEditMode ? "" : undefined);
    mainBody = mainBody || (isInEditMode ? [{}] : undefined);
    const themeContext = useContext(ThemeContext);
    if (!theme) theme = (themeContext && themeContext.theme) || "lightgray";

    return (
        <ThemeProvider theme={{ theme: theme }}>
            <Container key={identifier} id={anchorName} className={className}>
                {heading && (
                    <Heading
                        $hasIcon={!!icon}
                        $iconColor={icon}
                        {...addEditAttributes("Heading")}
                        dangerouslySetInnerHTML={{ __html: heading }}
                    />
                )}
                {mainBody && (
                    <StyledTextBlock
                        content={mainBody}
                        areaName="mainBody"
                        lightbox={lightbox}
                        {...addEditAttributes("MainBody")}
                    />
                )}
                {children}
                {documentLinks && documentLinks.length > 0 && (
                    <Documents>
                        {documentLinks.map((document, index) => (
                            <Document
                                key={document.identifier || `document${index}`}
                                {...document}
                            />
                        ))}
                    </Documents>
                )}
            </Container>
        </ThemeProvider>
    );
};

export default React.memo(FactBox);
